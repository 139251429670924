import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import { Parallax, Background } from 'react-parallax';
import imgMobile from "../../assets/image/jpeg/toxane-award.svg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'
import trust from "../../assets/image/png/google-icon-white.svg";


const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #1a1a1a;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: rgba(111, 114, 118, 0.75) !important;
color: #AB8E66;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: #f1f1f1;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #f1f1f1;
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #f1f1f1;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;


const Texty = styled.p`
font-size:16px;
line-height:24px;
color: #f1f1f1;
@media screen and (max-width: 767px)
     {
      font-size:10px;
      line-height:16px;
      color: #f1f1f1;
      }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
display:block;
margin-top:5px;
  box-shadow: none;
  border-radius: 3px;
  max-width:40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
 
  @media screen and (max-width: 991px)
  {
  
     max-width:100%;
 }
`;

const AwardPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="rgb(171,142,102, 0.7)" py={4}>
      <Container className="pt-lg-5 pb-lg-5 mt-lg-2 mb-lg-2">
        <Row>
            <Col lg="6" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false">
            <Iwrap>


<Itext as="h2">Clinic Of Excellence</Itext>
</Iwrap>
            </ Col>
        </Row>

          <Row >

    
              <Col  lg="6" xs="7" className="mb-4 lg-pr-3 order-lg-1" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false">
           

      <Box className=" pt-lg-0">
          
          <Texty  color="#fff">
<br />
Dermamina has been honoured with the prestigious "Clinic of Excellence". This remarkable recognition serves as a testament to our unwavering commitment to providing exceptional services and delivering the highest standard of patient care. We strive to create an environment where patients feel valued and supported throughout their journey. We are inspired to continue our pursuit of excellence to provide the best possible care to our valued patients.






          </Texty> 
       
        </Box>
              </Col>
              <Col lg="6" xs="5" className="order-lg-2  mb-lg-0" data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false">
            <div
              className="pl-2"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="What is non surgical rhinoplasty" className="img-fluid" />
            </div>
          </Col>
     
         
        </Row>
       
      </Container>
    </Section>
  </>
);

export default AwardPage;
